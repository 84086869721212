#copyrightSection{
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 100%;
    height: 2.5rem;
    color: black;
}

#copyright{
    height: 20px;
    width: auto;
    vertical-align: middle;
}