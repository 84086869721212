.navBarContainer{
    display: flex;
    height: 80px;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    overflow: hidden;
    top: 0;
    margin-bottom: 4em;
}


.logo{
    text-decoration: none;
    color: black;
    font-size: 40px;
    cursor: pointer;
    font-weight: bold;
    font-family: fira code;
}

.navItems{
    display: flex;
    flex-direction: row;
    width: 40%;
    justify-content: space-between;
    font-weight: bold;
    font-family: fira code;
    align-items: center;
}

.navItem{
    font-size: 20px;
    text-decoration: none;
    color: black;
    display: block;
}

.navItem:hover, .dropdown:hover .dropBtn, .navItem.active{
    text-decoration: underline;
    text-decoration-color: black;
    text-underline-offset: 5px;
    text-decoration-style: wavy;
}

#instagram img, #email img{
    height: 20px;
    cursor: pointer;
    display: block;
    margin: auto;

}


.downArrow{
    height: 12px;
    width: auto;
    display: inline-block;
}

.dropdown{
    overflow: hidden;
    float: left;
}

.dropdown .dropBtn{
    border: none;
    color: black;
    background-color: transparent;
    outline: none;
    font-size: 20px;
    font-weight: bold;
    margin: 0;
    padding: 0;
    font-family: fira code;
}

.dropdownContent{
    display: none;
    position: absolute;
    z-index: 1;
    top: 50px;
}

.dropLink{
    text-decoration: none;
    display: block;
    color: black;
    font-size: 14px;
    font-weight: normal;
    margin: 1px 0;
    font-family: fira code;
}

.dropLink:hover{
    font-weight: bold;
}


.dropdown:hover .dropdownContent{
    display: flex;
    flex-direction: column;
    padding-top: 5px;
}

.menuIcon{
    display: none;
}




@media screen and (max-width:1000px) {
    .menuIcon{
        display: block;
        position: fixed;
        right: 4em;
        cursor: pointer;
        z-index: 10;
    }

    .menuIcon img{
        height: 32px;
        width: auto;
    }
}

@media screen and (max-width: 1000px) {
    .navItems{
        position: absolute;
        display: flex;
        flex-direction: column;
        top: 0;
        width: 100%;
        height: 100vh;
        left: -100%;
        opacity: 1;
        transition: all 0.5s ease;
        justify-content: space-evenly;
        align-items: center;
        padding-top: 10vh;
        padding-bottom: 30vh;

    }

    .navItems.active{
        left: 0;
        opacity: 1;
        transition: all 0.5s ease;
        z-index: 1;    
    }

    .navItems, .navItems.active .dropdown .dropBtn{
        background-color: #BBC9C4;
    }

    .navItem, .dropdown .dropBtn{
    display: none;
    font-size: 20px;
    }

    .navItems.active .navItem{
        float: none;
        display: block;
        text-align: center;
    }

    .navItems.active .dropdown{
        float: none;
    }
    .navItems.active .dropdownContent{
        position: static;
    }

    .navItems.active .dropLink{
        text-align: center;
        margin: 10px 0;
    }
    .navItems.active .dropdown .dropBtn{
        display: block;
        width: 100%;
        text-align: center;
    }
}

@media screen and (max-width: 390px) {
    .logo{
        left: 16px; 
    }
    .menuIcon{
        right: 16px;
    }
}

