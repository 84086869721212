.seriesWrapper{
    position: relative;
    z-index: 0;
}

.seriesWrapper img{
    width: 100%;
    height: 100%;
    object-fit: cover;

}

.seriesTitle{
    position: absolute;
    top: 0; bottom: 0; right: 0; left: 0;
    color: black;
    margin-top: 0;
    margin-bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(255, 255, 255, .5);
    visibility: hidden;
    transition: opacity .2s, visibility .2s;
    opacity: 0;
    font-weight: normal;
    font-size:20px;
}

.seriesWrapper:hover .seriesTitle{
    visibility: visible;
    opacity: 1;
}


.seriesWrapper{
    position: relative;
    z-index: 0;
}

.seriesWrapper img{
    width: 100%;
    height: 100%;
    object-fit: cover;

}

.seriesTitle{
    position: absolute;
    top: 0; bottom: 0; right: 0; left: 0;
    color: black;
    margin-top: 0;
    margin-bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(255, 255, 255, .5);
    visibility: hidden;
    transition: opacity .2s, visibility .2s;
    opacity: 0;
    font-weight: normal;
    font-size:20px;
}

.seriesWrapper:hover .seriesTitle{
    visibility: visible;
    opacity: 1;
}

