.masonry{
    margin-bottom: 2em;
}
.galleryImg{
    display: block;
    width: 100%;
    cursor: zoom-in;
}

.modal{
    position: fixed;
    width: 100vw;
    height: 100%;
    right: 0;
    top: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: rgba(255,255,255,1);
    z-index: 15;
}


.modalImg{
    display: block;
    object-fit: contain;
    max-height: 70vh;
    max-width: 70vw;
    margin-bottom: 20px;
}

.imgDesc{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    bottom: 3%;
    position: absolute;
    text-align: center;
}

.imgTitle{
    font-weight: normal;
    font-size: 18px;
    width: 90%;
}

.imgDesc p{
    line-height: 1;
    font-weight: 300;
    width: 80%;
    display: inline-block;
    word-break: break-word;
    font-size: 14px;
    position: relative;
    top: -18px;
}

.close{
    color: black;
    position: absolute;
    cursor: pointer;
    width: 20px;
    top: 20px;
    right: 10%;
}

.previous, .next{
    cursor: pointer;
    color: black;
    top: 50%;
    width: 50px;
    position: absolute;
}

.previous{
    left: 10%;
}

.next{
    right: 10%;
}

@media screen and (max-width:600px) {
    .previous{
        left: 5%;
    }
    .next{
        right: 5%;
    }
    .close{
        right: 5%;
    }
}

@media screen and (max-width:390px) {
    .modalContainer{
        height: calc(100vh - 60px);
    }

    .imgTitle{
        font-size: 14px;
        width: 90vw;
    }

    .imgDesc p{
        font-size: 12px;
    }
}