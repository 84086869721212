*{
    box-sizing: border-box;
}

.slider{
    display: flex;
    flex-direction: row;
    padding: 70px 5% 1em 5%;
    height: calc(100vh - 80px - 2.5rem);
    justify-content: center;
}

.thumbRow{
    display: flex;
    flex-direction: column;
    height: 90%;
    overflow-x: hidden;
    overflow-y: auto;
    margin-right: 1em;
    gap: 4px;
}

.thumbnail{
    width: 240px;
    height: 180px;
    cursor: pointer;
}

.thumbnail img{
    object-fit: cover;
    width: 100%;
    height: 100%;
}


.mainRow{
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    width: calc(90% - 240px - 1em);
    height: 100%;
}

.mainImg{
    max-height: 90%;
    width: 100%;
    object-fit: contain;
    display: block;
}

.titleRow{
    display: flex;
    flex-direction: row;
    height: 8%;
    width: 60%;
    justify-content: space-between;
    align-items: center;
}
.titleRow h2{
    font-size: 20px;
}

.leftArrow, .rightArrow{
    width: 40px;
    height: auto;
    cursor: pointer;
}

@media screen and (max-width: 1000px) {
    .slider{
        padding:5%;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .thumbRow{
        flex-direction: row;
        width: 100%;
        height: 90px;
        gap: 4px;
        margin-right: 0;
        margin-bottom: 1rem;
        overflow-x: auto;
        overflow-y: hidden;
    }

    .thumbnail{
        min-height: 90px;
        min-width: 120px;
    }

    .mainRow{
        width: 100%;
        height: calc(95% - 90px);
    }

    .titleRow{
        width: 90%;
    }

}

@media screen and (max-width: 700px) {
    .mainRow{
       height:50%;
    }

    .thumbRow{
        margin-bottom: 2em;
    }

    .titleRow h2{
        font-size: 18px;
    }
}

@media screen and (max-width: 390px) {
    .slider{
        padding: 0 2em;
    }
    .titleRow{
        width: 100%;
    }
    .titleRow h2{
        font-size: 10px;
    }
}