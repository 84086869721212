.singleSeries{
    display: flex;
    flex-direction: column;
    line-height: .8;
}

.seriesDesc h1{
    font-size: 20px;
}

.seriesDesc h3{
    font-size: 16px;
}

.singleSeries p{
    line-height: 1.5em;
    background-color: rgba(255,255,255,.7);
    padding: 1em 2em;
}

.singleSeries p:empty{
   display: none;
}

.seriesImgs{
    flex-direction: row;
    flex-wrap: wrap;
}

.seriesImgs img{
    width: 100%;
    max-width: 400px;
    height: auto;
}

.navigation{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.left, .right{
    display: block;
    width: 40px;
    cursor: pointer;
}

