@font-face {
    font-family: 'Fira Code';
    src: url('./assets/fonts/FiraCode-Regular.ttf');
}


@font-face {
    font-family: 'Source Code Pro';
    src: url('./assets/fonts/SourceCodePro-VariableFont_wght.ttf');
}



.pageContainer{
    padding: 0 4em 2em 4em;
    min-height: 100vh;
}

#aboutPage, #sketchbookPage, .galleryPage{
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

#aboutPage{
    background-image: url('./assets/images/aboutBCKG.jpg');
    display: flex;
    flex-direction: column;
    align-items: center;
}

#sketchbookPage{
    background-image: url('./assets/images/sketchBCKG.jpg');
}

.galleryPage {
    background-image: url('./assets/images/galleryBCKG.jpg');
}

.pageHeader{
    display: flex;
    width: 450px;
    justify-content: center;
    flex-wrap: wrap;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 35px;
    margin-top: 30px;
    text-align: center;
}


.sectionTitle, .sectionYear{
    font-family: "Roboto";
    color: black;
    font-size: 20px;
    
}

.sectionTitle{
    margin-right: 16px;
}
.sectionMatl{
    font-family: Roboto;
}

h1, h2, h3{
    font-family: 'Roboto';
    color:black;
}




@media screen and (max-width: 390px) {
    .pageContainer{
        padding: 0 16px 2em 16px;
    }
    .pageHeader{
        width: 100%;
        flex-direction: column;
        margin-top: 0;
    }
    .sectionTitle, .sectionYear{
        font-size: 16px;
    }

    .sectionTitle{
        margin: 0;
    }
    .sectionMatl{
        font-size: 12px;
    }
}
