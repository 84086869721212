.aboutContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: calc(100vh - 6.5rem - 80px);
    width: 70%;
    gap: 1em;
}

.profileWrapper, .statementWrapper{
    font-size: 16px;
    line-height: 1.8em;
    background-color: rgba(255,255,255,0.7);
    padding: 1em 2rem;
    min-width: 100%;
}

.statementTop{
    font-style: italic;
    font-size: 20px;
    font-weight: 500;
}

.note p{
    font-style: italic;
    line-height: 1.2em;
}


.statementBottom{
    font-size: 16px;
}

.footnotes{
    font-size: 14px;
}

sup{
    line-height: 0;
}


@media screen and (max-width: 750px) {
   .aboutContainer{
    width: 100%;
   }

    .profileWrapper,.statementBottom{
    font-size: 14px;
    line-height: 1.8em;
   }

   .statementTop{
    font-size: 18px;
   }
}